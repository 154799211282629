let url = "";
let isInProd = false;

if (process.env.NODE_ENV === 'development') {
	url = "http://localhost:8000";
} else if (process.env.REACT_APP_STAGE === 'homolog') {
	url = "https://dev.api.cashbarber.com.br";
} else {
	url = "https://api.cashbarber.com.br";
	isInProd = true;
}

export const Constants = {
	url: url,
	baseUrl: url + "/api/admin",
	isInProd
};
